import axios from 'axios';

type PostVehicleRegisterPayload = {
  solution: string;
  provider: string;
  car_number: string;
  owner_name: string;
};

type PostVehicleRegisterResponse = {
  id: number;
  is_success: boolean;
};

export const postVehicleRegisterAPI = async (payload: PostVehicleRegisterPayload) =>
  (await axios.post<PostVehicleRegisterResponse>(`/admin/car_information/vehicle_register/`, payload)).data;
