import axios from 'axios';
import { all, put, delay, takeEvery } from 'redux-saga/effects';
import { get, toString } from 'lodash';
import { PayloadAction } from 'typesafe-actions';
import { ToastActions, ToastTypes } from '../store/toast';
import { Toast, ToastStatus, ToastVariant } from '../declaration/toast';
import { UserActions } from '../store/user';

export default function* () {
  yield all([asyncSagaAction(), createToast()]);
}

function* createToast() {
  yield takeEvery(ToastTypes.createToast, function* (action: PayloadAction<string, Toast>) {
    const toast = action.payload;
    yield delay(toast.duration);
    yield put(ToastActions.updateToast({ id: toast.id, status: ToastStatus.Hide }));
  });
}

function* asyncSagaAction() {
  yield takeEvery('*', function* (action: PayloadAction<string, any>) {
    if (/\/FAILURE$/g.test(action.type)) {
      const response = get(action, ['payload', 'response']);
      const status = get(response, 'status');
      const toastMessage = get(response, ['data', 'toast', 'message']);

      if (toastMessage) {
        yield put(ToastActions.createToast({ variant: ToastVariant.Error, message: toastMessage }));
      } else if (/5\d*/.test(toString(status))) {
        yield put(ToastActions.createToast({ variant: 1, message: '알 수 없는 오류가 발생했습니다.' }));
      }

      if (status === 401) {
        delete axios.defaults.headers['authorization'];
        localStorage.removeItem('token');
        yield put(UserActions.logout());
      }
    }
  });
}
