import React, { FC, memo } from 'react';
import styles from './CarDetail.module.scss';
import { CarsInformationDetail } from '../../declaration/cars';
import { get } from 'lodash';
import VehicleIdentification from './VehicleIdentification/VehicleIdentification';
import CarRecords from './CarRecords/CarRecords';
import CarHistory from './CarHistory/CarHistory';

interface Props {
  data: CarsInformationDetail;
}

const CarDetail: FC<Props> = memo(({ data }) => {
  const vehicleIdentification = get(data, 'vehicle_identification');
  const carHistory = get(data, 'carhistory');
  const etc = get(data, 'etc');

  const ownerChangedList = get(carHistory, 'owner_changed_list');
  const ownerChangedCount = get(carHistory, 'owner_changed_count');
  const carInformationChangedList = get(carHistory, 'car_information_changed_list');
  const carNumberChangedCount = get(carHistory, 'car_number_changed_count');

  return (
    <div className={styles.carDetail}>
      <VehicleIdentification data={vehicleIdentification} />
      <CarRecords
        ownerChangedList={ownerChangedList}
        ownerChangedCount={ownerChangedCount}
        carNumberChangedCount={carNumberChangedCount}
        carInformationChangedList={carInformationChangedList}
      />
      <CarHistory etc={etc} data={carHistory} />
    </div>
  );
});

export default CarDetail;
