import { useDispatch } from 'react-redux';
import { ToastActions } from '../store/toast';
import { ToastVariant } from '../declaration/toast';

export function useToast() {
  const dispatch = useDispatch();

  return {
    info: (message: string) => dispatch(ToastActions.createToast({ variant: ToastVariant.Info, message })),
    error: (message: string) => dispatch(ToastActions.createToast({ variant: ToastVariant.Error, message })),
    success: (message: string) => dispatch(ToastActions.createToast({ variant: ToastVariant.Success, message }))
  };
}
