import React from 'react';
import CarSpec from '../../components/CarSpec/CarSpec';
import CarSpecForm from './CarSpecForm/CarSpecForm';

const CarSpecPage: React.FC<{}> = () => {
  return (
    <>
      <CarSpecForm />
      <CarSpec />
    </>
  );
};

export default CarSpecPage;
