import { all, call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction, action } from 'typesafe-actions';

type Parameters<F extends Function> = F extends (...args: infer T) => any ? T : never;

const suffixNames: Array<string> = ['CANCEL', 'INITIAL', 'REQUEST', 'SUCCESS', 'FAILURE'];

export function createSuffixTypes(type: string) {
  return {
    cancel: `${type}/${suffixNames[0]}`,
    initial: `${type}/${suffixNames[1]}`,
    request: `${type}/${suffixNames[2]}`,
    success: `${type}/${suffixNames[3]}`,
    failure: `${type}/${suffixNames[4]}`,
    original: type
  };
}

export function createSuffixTypeArray(type: string) {
  return suffixNames.map(i => `${type}/${i}`);
}

export interface AsyncSagaActionCreator<A extends string, T extends Function> extends Function {
  (...args: Parameters<T>): PayloadAction<A, Parameters<T>>;
  sagaCreator: () => IterableIterator<any>;
  cancel: string;
  initial: string;
  request: string;
  success: string;
  failure: string;
  original: string;
}

export default function createAsyncSagaAction<A extends string, T extends Function>(
  type: A,
  asyncCreator: T
): AsyncSagaActionCreator<A, T> {
  const types = createSuffixTypes(type);

  function actionCreator(...args: Array<any>) {
    return action(types.initial, args);
  }

  function* iterator(...args: any) {
    yield put(action(types.request));

    const asyncCreatorArguments = args.map(({ payload }: { payload: any }) => payload);
    const callArguments: Array<any> = [];

    asyncCreatorArguments.map((argument: any) => {
      callArguments.push(...argument);
      return null;
    });

    try {
      const payload = yield call(asyncCreator as any, ...callArguments);
      yield put(action(types.success, payload, callArguments));
    } catch (e) {
      yield put(action(types.failure, e, callArguments));
    }
  }

  function* sagaCreator() {
    yield all([takeEvery(types.initial, iterator)]);
  }

  actionCreator.sagaCreator = sagaCreator;
  actionCreator.original = types.original;
  actionCreator.initial = types.initial;
  actionCreator.request = types.request;
  actionCreator.success = types.success;
  actionCreator.failure = types.failure;

  return actionCreator as any;
}
