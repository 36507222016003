import axios, { AxiosResponse, CancelToken } from 'axios';
import produce from 'immer';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { get } from 'lodash';
import { Action, handleActions } from 'redux-actions';
import { action, PayloadAction, PayloadMetaAction } from 'typesafe-actions';
import { CarSpec, CarsInformationDetail, VehicleRegister, VehicleRegisterForm } from '../declaration/cars';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';

export enum CarsTypes {
  FIND_CAR_INFORMATION = '@cars/FIND_CAR_INFORMATION',
  TRUNCATE_CAR_INFORMATION = '@cars/TRUNCATE_CAR_INFORMATION',
  GET_CAR_INFORMATION = '@cars/GET_CAR_INFORMATION',
  SET_CAR_INFORMATION_DETAIL = '@cars/SET_CAR_INFORMATION_DETAIL',
  GET_CAR_INFORMATION_DETAIL = '@cars/GET_CAR_INFORMATION_DETAIL',
  GET_CAR_SPEC = '@cars/GET_CAR_SPEC',
  GET_VEHICLE_REGISTER_ID = '@cars/GET_VEHICLE_REGISTER_ID',
  GET_VEHICLE_REGISTER = '@cars/GET_VEHICLE_REGISTER',

  SET_VEHICLE_REGISTER_FORM_SOLUTION = '@cars/SET_VEHICLE_REGISTER_FORM_SOLUTION',
  SET_VEHICLE_REGISTER_FORM_PROVIDER = '@cars/SET_VEHICLE_REGISTER_FORM_PROVIDER',
  SET_VEHICLE_REGISTER_FORM_CAR_NUMBER = '@cars/SET_VEHICLE_REGISTER_FORM_CAR_NUMBER',
  SET_VEHICLE_REGISTER_FORM_OWNER_NAME = '@cars/SET_VEHICLE_REGISTER_FORM_OWNER_NAME',
  TRUNCATE_VEHICLE_REGISTER_FORM = '@cars/TRUNCATE_VEHICLE_REGISTER_FORM',
}

export interface CarsState {
  isStop: boolean;
  carSpecMap: { [key: string]: CarSpec };
  carInformationList: Array<Array<CarsInformationDetail>>;
  carInformationDetailMap: { [key: number]: CarsInformationDetail };
  vehicleRegister: null | VehicleRegister;
  vehicleRegisterForm: VehicleRegisterForm;
}

export const CarsActions = {
  findCarInformation: createAsyncSagaAction(
    CarsTypes.FIND_CAR_INFORMATION,
    ({ carNumber, types, disableCache }: { carNumber: string; types: Array<string>; disableCache: boolean }) => {
      return axios.post('/admin/car_information/', { car_number: carNumber, types, disable_cache: disableCache });
    }
  ),
  getCarInformation: createAsyncSagaAction(CarsTypes.GET_CAR_INFORMATION, (query: URLSearchParams, cancelToken?: CancelToken) => {
    return axios.get('/admin/car_information/', { params: query, cancelToken });
  }),
  setCarInformationDetail: createAsyncSagaAction(CarsTypes.SET_CAR_INFORMATION_DETAIL, (id: number, data: any) => {
    return axios.patch(`/admin/car_information/${id}/`, data);
  }),
  getCarInformationDetail: createAsyncSagaAction(CarsTypes.GET_CAR_INFORMATION_DETAIL, (id: number) => {
    return axios.get(`/admin/car_information/${id}/`);
  }),
  getCarSpec: createAsyncSagaAction(CarsTypes.GET_CAR_SPEC, (vin: string) => {
    return axios.get(`/admin/car_information/car_spec/?vin=${vin}`);
  }),
  truncateCarInformation: () => {
    return action(CarsTypes.TRUNCATE_CAR_INFORMATION);
  },
  getVehicleRegister: createAsyncSagaAction(CarsTypes.GET_VEHICLE_REGISTER, (vehicleRegisterId: number) => {
    return axios.get(`/admin/car_information/vehicle_register/${vehicleRegisterId}/`);
  }),
  setVehicleRegisterFormSolution: (solutionIndex: number) =>
    action(CarsTypes.SET_VEHICLE_REGISTER_FORM_SOLUTION, { solutionIndex }),
  setVehicleRegisterFormProvider: (providerIndex: number) =>
    action(CarsTypes.SET_VEHICLE_REGISTER_FORM_PROVIDER, { providerIndex }),
  setVehicleRegisterFormCarNumber: (carNumber: string) => action(CarsTypes.SET_VEHICLE_REGISTER_FORM_CAR_NUMBER, { carNumber }),
  setVehicleRegisterFormOwnerName: (ownerName: string) => action(CarsTypes.SET_VEHICLE_REGISTER_FORM_OWNER_NAME, { ownerName }),
  truncateVehicleRegisterForm: () => action(CarsTypes.TRUNCATE_VEHICLE_REGISTER_FORM),
};

const initialState: CarsState = {
  isStop: false,
  carSpecMap: {},
  carInformationList: [],
  carInformationDetailMap: {},
  vehicleRegister: null,
  vehicleRegisterForm: {
    solutionIndex: 1,
    providerIndex: 1,
    carNumber: '',
    ownerName: '',
  },
};

export default handleActions<CarsState, any>(
  {
    [CarsTypes.TRUNCATE_CAR_INFORMATION]: (state, action) => {
      return produce(state, (draft) => {
        draft.isStop = false;
        draft.carInformationList = [];
      });
    },
    ...createAsyncSagaReducerMap(CarsTypes.GET_CAR_INFORMATION, {
      onSuccess: (
        state,
        action: PayloadMetaAction<
          string,
          AxiosResponse<{
            meta: { count: number; page_size: number; next: string | null };
            results: Array<CarsInformationDetail>;
          }>,
          [URLSearchParams]
        >
      ) => {
        return produce(state, (draft) => {
          const index = Number(action.meta[0].get('page')) - 1;

          if (!isNaN(index)) {
            draft.isStop = action.payload.data.meta.next === null;
            draft.carInformationList[index] = action.payload.data.results;
          }
        });
      },
    }),
    ...createAsyncSagaReducerMap(CarsTypes.GET_CAR_SPEC, {
      onSuccess: (state, action: PayloadMetaAction<string, AxiosResponse<CarSpec>, [string]>) => {
        const vin = get(action.meta, 0);

        return produce(state, (draft) => {
          draft.carSpecMap[vin] = action.payload.data;
        });
      },
    }),
    ...createAsyncSagaReducerMap(CarsTypes.GET_CAR_INFORMATION_DETAIL, {
      onSuccess: (state, action: PayloadMetaAction<string, AxiosResponse<CarsInformationDetail>, [number]>) => {
        const id = get(action.meta, 0);

        return produce(state, (draft) => {
          draft.carInformationDetailMap[id] = action.payload.data;
        });
      },
    }),
    ...createAsyncSagaReducerMap(CarsTypes.GET_VEHICLE_REGISTER, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<VehicleRegister>>) => {
        return produce(state, (draft) => {
          draft.vehicleRegister = action.payload.data;
        });
      },
    }),
    [CarsTypes.SET_VEHICLE_REGISTER_FORM_SOLUTION]: (state, action: Action<{ solutionIndex: number }>) => {
      return produce(state, (draft) => {
        draft.vehicleRegisterForm.solutionIndex = action.payload.solutionIndex;
      });
    },
    [CarsTypes.SET_VEHICLE_REGISTER_FORM_PROVIDER]: (state, action: Action<{ providerIndex: number }>) => {
      return produce(state, (draft) => {
        draft.vehicleRegisterForm.providerIndex = action.payload.providerIndex;
      });
    },
    [CarsTypes.SET_VEHICLE_REGISTER_FORM_CAR_NUMBER]: (state, action: Action<{ carNumber: string }>) => {
      return produce(state, (draft) => {
        draft.vehicleRegisterForm.carNumber = action.payload.carNumber;
      });
    },
    [CarsTypes.SET_VEHICLE_REGISTER_FORM_OWNER_NAME]: (state, action: Action<{ ownerName: string }>) => {
      return produce(state, (draft) => {
        draft.vehicleRegisterForm.ownerName = action.payload.ownerName;
      });
    },
    [CarsTypes.TRUNCATE_VEHICLE_REGISTER_FORM]: (state, action: Action<{ ownerName: string }>) => {
      return produce(state, (draft) => {
        draft.vehicleRegisterForm = initialState.vehicleRegisterForm;
      });
    },
  },
  initialState
);
