export enum ToastVariant {
  Info,
  Error,
  Success
}

export enum ToastStatus {
  Display,
  Hide,
  Remove
}

export interface Toast {
  id: string;
  status: ToastStatus;
  variant: ToastVariant;
  message: string;
  duration: number;
}

export interface NewToast {
  variant: ToastVariant;
  message: string;
}

export interface UpdateToast {
  id: string;
  status?: ToastStatus;
  variant?: ToastVariant;
  message?: string;
}
