import { fork } from 'redux-saga/effects';
import { AsyncSagaActionCreator } from './createAsyncSagaAction';

function isAsyncSagaCreator(value: any): value is AsyncSagaActionCreator<any, any> {
  return value.hasOwnProperty('sagaCreator');
}

export function createAsyncSaga(actionMap: { [key: string]: any }) {
  const sagas = [];

  for (const key in actionMap) {
    const action = actionMap[key];

    if (isAsyncSagaCreator(action)) {
      sagas.push(action.sagaCreator);
    }
  }

  return sagas.map((saga) => fork(saga));
}
