import React, { FC } from 'react';
import styles from './CarAccidents.module.scss';
import { CarAccidentItem } from '../../../../declaration/cars';
import { map, get, size } from 'lodash';
import { toTenThousandWonFormat } from '../../../../cores/toTenThousandWonFormat';
import classNames from 'classnames';

interface Props {
  label: string;
  value: Array<CarAccidentItem>;
}

const CarAccidents: FC<Props> = ({ label, value }) => {
  const isEmpty = size(value) <= 0;

  return (
    <div className={classNames(styles.carAccidents, isEmpty && styles.isEmpty)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>
        {isEmpty && <span className={styles.empty}>정보없음</span>}
        {map(value, (data, key) => {
          const accidentDate = get(data, 'accident_date');
          const insuranceMoney = get(data, 'insurance_money');
          const component = get(data, 'component');
          const painting = get(data, 'painting');
          const wage = get(data, 'wage');

          return (
            <div key={key} className={styles.item}>
              <div className={styles.head}>
                <span>{accidentDate}</span>
                <span className={styles.insuranceMoney}>총 {toTenThousandWonFormat(insuranceMoney)}</span>
              </div>
              <div className={styles.component}>부품 {toTenThousandWonFormat(component)}</div>
              <div className={styles.painting}>도장 {toTenThousandWonFormat(painting)}</div>
              <div className={styles.wage}>공임 {toTenThousandWonFormat(wage)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CarAccidents;
