import { useCallback, useState } from 'react';

export function useHover(): [boolean, () => void, () => void] {
  const [isHover, setHover] = useState(false);

  const onMouseOver = useCallback(() => {
    setHover(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return [isHover, onMouseOver, onMouseLeave];
}
