import axios from 'axios';

type PostCarInformationMerchandiseCheckPayload = {
  car_number: string;
};

type PostCarInformationMerchandiseCheckResponse = {
  merchandise_check_result: 'merchandise' | 'not_merchandise' | 'not_found' | 'error';
  items:
    | {
        label: string;
        value: string | null;
      }[]
    | null;
  raw_data: string | null;
};

export const postCarInformationMerchandiseCheckAPI = async (payload: PostCarInformationMerchandiseCheckPayload) =>
  (await axios.post<PostCarInformationMerchandiseCheckResponse>(`/admin/car_information/merchandise_check/`, payload)).data;
