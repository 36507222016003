import React, { FC, useEffect, useRef } from 'react';
import styles from './VehicleIdentification.module.scss';
import { VehicleIdentification as IVehicleIdentification } from '../../../declaration/cars';
import { get, map, size } from 'lodash';
import Field from '../../Field/Field';
import { toMileageFormat } from '../../../cores/toMileageFormat';
import { toDateFormat } from '../../../cores/toDateFormat';
import { toTenThousandWonFormat } from '../../../cores/toTenThousandWonFormat';
import CopyText from '../../CopyText/CopyText';
import { FiSearch } from 'react-icons/fi';

interface Props {
  data: IVehicleIdentification;
}

const VehicleIdentification: FC<Props> = ({ data }) => {
  const year = get(data, 'year');
  const initialRegistrationDate = get(data, 'initial_registration_date');
  const carNumber = get(data, 'car_number');
  const carName = get(data, 'car_name');
  const color = get(data, 'color');
  const options = get(data, 'options');
  const mileage = get(data, 'mileage');
  const vehicleIdentificationNumber = get(data, 'vehicle_identification_number');
  const carSpecCarName = get(data, 'car_spec_car_name');
  const factoryPrice = get(data, 'factory_price');
  const yearDisplay = year ? `${year}년` : null;

  return (
    <div className={styles.vehicleIdentification}>
      <Field label="차량번호" value={<CopyText>{carNumber}</CopyText>} />
      <Field label="차명" value={carName} />
      <Field label="사양" value={carSpecCarName} />
      <Field label="색상" value={color} />
      <Field label="주행거리" value={toMileageFormat(mileage)} />
      <Field label="연식" value={yearDisplay} />
      <Field label="최초등록일" value={toDateFormat(initialRegistrationDate)} />
      <Field label="출고가" value={toTenThousandWonFormat(factoryPrice)} />
      <Field label="차대번호" value={vehicleIdentificationNumber ? (
        <div>
          <CopyText>
            {vehicleIdentificationNumber}
          </CopyText>
          <button className={styles.vin} onClick={() => {
            if (!vehicleIdentificationNumber) {
              return
            }

            window.open(`/vin?a=${vehicleIdentificationNumber}&car_number=${carNumber}`, '', 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=100,top=100')
          }}>
            <FiSearch />
          </button>
        </div>
      ) : '-'} />
      <Field
        label="제조사 정보조회"
        value={
          options !== null && Array.isArray(options) && size(options) > 0 ? (
            <div className={styles.options}>
              {map(options, (option, key) => {
                const name = get(option, 'name');
                const price = get(option, 'price');

                return (
                  <div className={styles.option} key={key}>
                    <span className={styles.name}>{name}</span>
                    <span>{toTenThousandWonFormat(price) || '-'}</span>
                  </div>
                );
              })}
            </div>
          ) : null
        }
      />
    </div>
  );
};

export default VehicleIdentification;
