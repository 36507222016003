import React, { FC, FormEventHandler, useState } from 'react';
import styles from './MerchandiseCheckPage.module.scss';
import TextInput from '../../components/TextInput/TextInput';
import { FiSearch } from 'react-icons/fi';
import Button from '../../components/Button/Button';
import { useMutation } from 'react-query';
import { postCarInformationMerchandiseCheckAPI } from '../../apis';
import Ink from 'react-ink';
import Modal from '../../components/Modal/Modal';

const MerchandiseCheckPage: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, mutateAsync, status } = useMutation({
    mutationFn: postCarInformationMerchandiseCheckAPI,
  });
  const [carNumber, setCarNumber] = useState('');

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    if (status === 'loading' || !carNumber) return;

    await mutateAsync({ car_number: carNumber });
  };

  return (
    <div className={styles.wrapperStyle}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <TextInput
          value={carNumber}
          onChange={(e) => {
            setCarNumber(e.currentTarget.value);
          }}
          icon={<FiSearch />}
          placeholder="차량번호"
        />
        <Button loading={status === 'loading'}>조회하기</Button>
      </form>
      <hr />
      <div className={styles.content}>
        {!data ? (
          status === 'idle' ? (
            <div className={styles.status}>조회하기 버튼을 눌러주세요.</div>
          ) : status === 'error' ? (
            <div className={styles.status}>오류가 발생했습니다.</div>
          ) : null
        ) : data.merchandise_check_result !== 'merchandise' ? (
          <div>상품용 차량이 아닙니다.</div>
        ) : (
          <>
            <div className={styles.items}>
              {data.items?.map((item, idx) => (
                <div key={idx} className={styles.item}>
                  <div className={styles.label}>{item.label}</div>
                  <div className={styles.value}>{item.value}</div>
                </div>
              ))}
            </div>
            <div>
              {data.raw_data && (
                <button onClick={() => setIsOpen(true)} className={styles.rawDataButton}>
                  미가공 데이터
                  <Ink />
                </button>
              )}
            </div>
            <Modal isVisible={isOpen} onClose={() => setIsOpen(false)} className={styles.modal}>
              {data.raw_data && (
                <pre
                  dangerouslySetInnerHTML={{
                    __html: data.raw_data,
                  }}
                />
              )}
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default MerchandiseCheckPage;
