import rootSaga from '../sagas';
import reducers, { history } from '../store';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({});

export default (initialState?: any) => {
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);

  if ((module as any).hot) {
    (module as any).hot.accept('../store', () => {
      const nextReducers = require('../store').default;
      store.replaceReducer(nextReducers);
    });
  }

  return store;
};
