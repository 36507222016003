import { useMemo } from 'react';

export function useQueryField(query: URLSearchParams, key: string): string | number | null {
  return useMemo(() => {
    const value = query.get(key);

    if (value === null) {
      return null;
    }

    if (!/[-]{0,1}[\d]*[.]{0,1}[\d]+/g.test(value)) {
      return value;
    }

    const convertToNumberValue = Number(value);

    if (isNaN(convertToNumberValue)) {
      return value;
    }

    return convertToNumberValue;
  }, [key, query]);
}
