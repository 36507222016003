import React from 'react';
import styles from './CarSpecForm.module.scss';
import { useFormik } from 'formik';
import { FiSearch } from 'react-icons/fi';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import Button from '../../../components/Button/Button';
import TextInput from '../../../components/TextInput/TextInput';

interface CarSpecFormValues {
  vin: string;
}

const CarSpecForm: React.FC<{}> = () => {
  const history = useHistory();

  const onSubmit = (values: CarSpecFormValues) => {
    if (values.vin === '') {
      return;
    }
    history.push(`/vin?a=${values.vin}`);
  };

  const { values, handleChange, handleSubmit } = useFormik<CarSpecFormValues>({
    initialValues: {
      vin: '',
    },
    onSubmit,
  });
  return (
    <form onSubmit={handleSubmit} className={styles.CarSpecForm}>
      <div className={classNames(styles.column, styles.inputWrapper)}>
        <TextInput
          icon={<FiSearch />}
          name="vin"
          value={values.vin}
          inputClassName={styles.input}
          placeholder="차대번호"
          onChange={handleChange}
        />
      </div>
      <Button>조회하기</Button>
    </form>
  );
};

export default CarSpecForm;
