import React, { FC } from 'react';
import styles from './CarTags.module.scss';

interface Props {

}

const CarTags: FC<Props> = ({ children }) => {
  return (
    <div className={styles.carTags}>{children}</div>
  );
};

export default CarTags;
