import React, { FC } from 'react';
import styles from './CarRecords.module.scss';
import { CarInformationChangedItem, OwnerChangedItem } from '../../../declaration/cars';
import CarRecord from './CarRecord/CarRecord';
import { map, size } from 'lodash';
import CarRecordItem from './CarRecordItem/CarRecordItem';

interface Props {
  ownerChangedList: Array<OwnerChangedItem>;
  ownerChangedCount: number;
  carNumberChangedCount: number;
  carInformationChangedList: Array<CarInformationChangedItem>;
}

const CarRecords: FC<Props> = ({ ownerChangedList, ownerChangedCount, carNumberChangedCount, carInformationChangedList }) => {
  return (
    <div className={styles.carRecords}>
      {size(ownerChangedList) > 0 && (
        <CarRecord label="소유자 변경내역" count={ownerChangedCount}>
          {map(ownerChangedList, ({ owner_changed_date }, key) => (
            <CarRecordItem key={key}>{owner_changed_date}</CarRecordItem>
          ))}
        </CarRecord>
      )}
      {size(carInformationChangedList) > 0 && (
        <CarRecord label="차량번호 변경내역" count={carNumberChangedCount}>
          {map(carInformationChangedList, ({ car_information_changed_date, car_information_changed_type }, key) => (
            <CarRecordItem key={key}>
              {car_information_changed_date}
              <br />
              {car_information_changed_type}
            </CarRecordItem>
          ))}
        </CarRecord>
      )}
    </div>
  );
};

export default CarRecords;
