import React, { useCallback } from 'react';
import styles from './VehicleRegisterForm.module.scss';
import { useFormik } from 'formik';
import RadioGroups from '../RadioGroups/RadioGroups';
import TextInput from '../TextInput/TextInput';
import { FiSearch } from 'react-icons/fi';
import Button from '../Button/Button';
import { useDispatch } from 'react-redux';
import { chain, get } from 'lodash';
import { CarsActions } from '../../store/cars';
import classNames from 'classnames';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useMutation } from 'react-query';
import { postVehicleRegisterAPI } from '../../apis';
import { useHistory } from 'react-router';

interface VehicleRegisterFormValues {
  selectedProviderTypeIndex: number;
  selectedSolutionTypeIndex: number;
  carNumber: string;
  ownerName: string;
}

const providerTypes = [
  { name: 'ecar', value: 'ecar' },
  { name: '정부24', value: 'gov24' },
];

const solutionTypes = [
  { name: '인포테크', value: 'infotech' },
  { name: '코드에프', value: 'codef' },
];

const VehicleRegisterForm: React.FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { mutateAsync } = useMutation({
    mutationFn: postVehicleRegisterAPI,
    mutationKey: ['postVehicleRegister'],
  });

  const { vehicleRegisterForm } = useTypedSelector(({ cars: { vehicleRegisterForm } }) => ({ vehicleRegisterForm }));
  const solutionIndex = get(vehicleRegisterForm, 'solutionIndex');
  const providerIndex = get(vehicleRegisterForm, 'providerIndex');
  const carNumber = get(vehicleRegisterForm, 'carNumber');
  const ownerName = get(vehicleRegisterForm, 'ownerName');

  const onSubmit = useCallback(async () => {
    const selectedProviderTypeValue = chain(providerTypes).get(providerIndex).get('value').value();
    const selectedSolutionTypeValue = chain(solutionTypes).get(solutionIndex).get('value').value();

    const response = await mutateAsync({
      solution: selectedSolutionTypeValue,
      provider: selectedProviderTypeValue,
      car_number: carNumber,
      owner_name: ownerName,
    });

    history.push(`/register?id=${response.id}`);
  }, [providerIndex, solutionIndex, mutateAsync, carNumber, ownerName, history]);

  const { handleSubmit } = useFormik<VehicleRegisterFormValues>({
    initialValues: {
      selectedProviderTypeIndex: solutionIndex,
      selectedSolutionTypeIndex: providerIndex,
      carNumber: '',
      ownerName: '',
    },
    onSubmit,
  });
  return (
    <form onSubmit={handleSubmit} className={styles.vehicleRegisterForm}>
      <div className={classNames(styles.column, styles.inputWrapper)}>
        <TextInput
          icon={<FiSearch />}
          name="carNumber"
          value={carNumber}
          inputClassName={styles.input}
          placeholder="차량번호"
          onChange={(e) => {
            dispatch(CarsActions.setVehicleRegisterFormCarNumber(e.currentTarget.value));
          }}
        />
        <TextInput
          icon={<FiSearch />}
          name="ownerName"
          value={ownerName}
          inputClassName={styles.input}
          placeholder="이름"
          onChange={(e) => {
            dispatch(CarsActions.setVehicleRegisterFormOwnerName(e.currentTarget.value));
          }}
        />
      </div>
      <div className={styles.radioGroupWrapper}>
        <div className={styles.row}>
          <RadioGroups
            radios={providerTypes}
            selectedIndex={providerIndex}
            onClick={(index) => {
              dispatch(CarsActions.setVehicleRegisterFormProvider(index));
            }}
          />
        </div>
        <div className={styles.row}>
          <RadioGroups
            radios={solutionTypes}
            selectedIndex={solutionIndex}
            onClick={(index) => {
              dispatch(CarsActions.setVehicleRegisterFormSolution(index));
            }}
          />
        </div>
      </div>
      <Button>조회하기</Button>
    </form>
  );
};

export default VehicleRegisterForm;
