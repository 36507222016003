import React, { FC, ReactNode } from 'react';
import styles from './Field.module.scss';
import classNames from 'classnames';

interface Props {
  label: string;
  value: ReactNode | string | number | null;
}

function isEmpty(value: ReactNode | string | number | null) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else if (value === null || value === undefined) {
    return true;
  } else if (typeof value === 'string' && value === '') {
    return true;
  }

  return false;
}

const Field: FC<Props> = ({ label, value }) => {
  const no = isEmpty(value);

  return (
    <div className={classNames(styles.field, no && styles.isNo)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{no ? '정보없음' : value}</div>
    </div>
  );
};

export default Field;
