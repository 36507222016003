import React, { FC, useCallback, useMemo } from 'react';
import styles from './SearchForm.module.scss';
import { useFormik } from 'formik';
import { chain } from 'lodash';
import { convertToKorean } from '../../cores/convertToKorean';
import { FiSearch } from 'react-icons/fi';
import Button from '../Button/Button';
import TextInput from '../TextInput/TextInput';
import { useDispatch } from 'react-redux';
import { CarsActions } from '../../store/cars';
import RadioGroups from '../RadioGroups/RadioGroups';
import Check from '../Check/Check';

interface Props {}

interface SearchFormValues {
  disableCache: boolean;
  selectedSearchTypeIndex: number;
  carNumber: string;
}

const searchTypes = [
  { name: '이엠아이 + 보험 통합조회', value: ['vehicle_identification', 'carhistory'] },
  { name: '이엠아이 조회', value: ['vehicle_identification'] },
  { name: '보험 조회', value: ['carhistory'] },
];

const SearchForm: FC<Props> = () => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: SearchFormValues) => {
      const selectedSearchTypeValue = chain(searchTypes).get(values.selectedSearchTypeIndex).get('value').value();

      dispatch(
        CarsActions.findCarInformation({
          carNumber: values.carNumber,
          types: selectedSearchTypeValue,
          disableCache: values.disableCache,
        })
      );
    },
    [dispatch]
  );

  const { values, setFieldValue, handleSubmit } = useFormik<SearchFormValues>({
    initialValues: {
      disableCache: false,
      selectedSearchTypeIndex: 0,
      carNumber: '',
    },
    onSubmit,
  });

  const onChangeCarNumber = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setFieldValue('carNumber', convertToKorean(e.currentTarget.value));
    },
    [setFieldValue]
  );

  return (
    <div className={styles.searchForm}>
      <form onSubmit={handleSubmit}>
        <div className={styles.searchTypes}>
          <RadioGroups
            radios={searchTypes}
            selectedIndex={values.selectedSearchTypeIndex}
            onClick={(index) => {
              setFieldValue('selectedSearchTypeIndex', index);
            }}
          />
          <div className={styles.border} />
          <Check
            label="캐시 비활성화"
            checked={values.disableCache}
            onChange={(e) => {
              setFieldValue('disableCache', e.currentTarget.checked);
            }}
          />
        </div>
        <div className={styles.searchBar}>
          <TextInput
            icon={<FiSearch />}
            name="carNumber"
            value={values.carNumber}
            className={styles.input}
            placeholder="차량번호 검색"
            onChange={onChangeCarNumber}
          />
          <Button className={styles.submit} type="submit">
            조회
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
