import { all, take, takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { CarsActions } from '../store/cars';
import { get } from 'lodash';
import { ToastActions } from '../store/toast';
import { ToastVariant } from '../declaration/toast';

function* watchFindCarInformation() {
  yield takeEvery(CarsActions.findCarInformation.success, function* () {
    yield put(CarsActions.getCarInformation(new URLSearchParams({ envelope: 'true', page: '1' })));
  });
}

function* getCarInformation() {
  while (true) {
    const {
      payload: {
        data: { id },
        status,
      },
    } = yield take(CarsActions.findCarInformation.success);

    if (status !== 201) {
      return;
    }

    yield put(CarsActions.getCarInformationDetail(id));
    yield put(push(`?id=${id}`));
  }
}

function* setCarInformation() {
  while (true) {
    const {
      meta,
      payload: { status },
    } = yield take(CarsActions.setCarInformationDetail.success);

    if (status !== 200) {
      return;
    }

    const id = get(meta, 0);
    yield put(ToastActions.createToast({ variant: ToastVariant.Success, message: '코멘트가 작성되었습니다.' }));
    yield put(CarsActions.getCarInformationDetail(id));
  }
}

export default function* () {
  yield all([...createAsyncSaga(CarsActions), getCarInformation(), setCarInformation(), watchFindCarInformation()]);
}
