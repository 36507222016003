import { useCallback, useState } from 'react';

export function useFocus(initialFocus = false): [boolean, () => void, () => void] {
  const [isFocus, setFocus] = useState<boolean>(initialFocus);

  const onFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    setFocus(false);
  }, []);

  return [isFocus, onFocus, onBlur];
}
