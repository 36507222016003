import React from 'react';
import VehicleRegisterForm from '../components/VehicleRegisterForm/VehicleRegisterForm';
import VehicleRegister from '../components/VehicleRegister/VehicleRegister';

const VehicleRegisterPage: React.FC<{}> = () => {
  return (
    <>
      <VehicleRegisterForm />
      <VehicleRegister />
    </>
  );
};

export default VehicleRegisterPage;
