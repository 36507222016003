import React, { FC, HTMLAttributes, memo } from 'react';

import classNames from 'classnames';
import { MdCheck } from 'react-icons/md';

import { useHover } from '../../cores/useHover';

import styles from './Check.module.scss';

export interface Props {
  variant?: 'primaryBlue' | 'secondaryYellow';
  shapeClassName?: string;
  className?: string;
  label?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  value?: boolean;
}

const Check: FC<Props & HTMLAttributes<HTMLInputElement>> = memo(
  ({ variant = 'primaryBlue', shapeClassName, className, label, name, checked, disabled, value, ...otherProps }) => {
    const [isOver, onMouseOver, onMouseLeave] = useHover();

    return (
      <label
        className={classNames(
          className,
          styles[variant],
          styles.check,
          checked && styles.isChecked,
          isOver && styles.isOver,
          disabled && styles.isDisabled
        )}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <input
          value={value?.toString()}
          name={name}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          {...otherProps}
        />
        <div className={classNames(styles.shape, shapeClassName)}>
          <span className={styles.icon}>
            <MdCheck />
          </span>
        </div>
        {label && <span className={styles.label}>{label}</span>}
      </label>
    );
  }
);

Check.displayName = 'Check';
export default Check;
