import React, { FC } from 'react';
import styles from './CarHistory.module.scss';
import { CarHistory as ICarHistory, Etc } from '../../../declaration/cars';
import { get, map } from 'lodash';
import Field from '../../Field/Field';
import CarAccidents from './CarAccidents/CarAccidents';
import CarTag from './CarTags/CarTag/CarTag';
import CarTags from './CarTags/CarTags';

interface Props {
  etc: Etc;
  data: ICarHistory;
}

const dictionaryOrder = ['brand', 'model_group', 'model', 'grade', 'detail'];

const tags = [
  { name: '전손', value: 'total_loss_count' },
  { name: '침수전손', value: 'flooded_total_loss_count' },
  { name: '침수분손', value: 'flooded_part_loss_count' },
  { name: '도난', value: 'stolen_count' },
  { name: '대여용도이력', value: 'rent_use_record_count' },
  { name: '관용이력', value: 'public_use_record_count' },
  { name: '영업용도이력', value: 'business_use_record_count' }
];

function getSerializeDictionary(matchingCarMeta: any) {
  let depth = matchingCarMeta;
  const dictionaries: Array<{ key: any; value: any; hashId: any }> = [];

  for (let i = 0; i < dictionaryOrder.length; i++) {
    const order = dictionaryOrder[i];
    const value = get(depth, order);

    if (value) {
      dictionaries.push({
        key: order === 'grade' ? 'grades' : order,
        value: get(value, 'name'),
        hashId: get(value, 'hash_id')
      });

      depth = value;
    }
  }

  return dictionaries;
}

const CarHistory: FC<Props> = ({ etc, data }) => {
  const carNumber = get(data, 'car_number');
  const year = get(data, 'year');
  const yearDisplay = year ? `${year}년` : null;
  const carName = get(data, 'car_name');
  const initialRegistrationDateDisplay = get(data, 'initial_registration_date_display');
  const fuelDisplay = get(data, 'fuel_display');
  const matchingCarMeta = get(etc, 'matching_car_meta');
  const serializeDictionary = getSerializeDictionary(matchingCarMeta);
  const myCarAccidentList = get(data, 'my_car_accident_list');
  const otherCarAccidentList = get(data, 'other_car_accident_list');

  return (
    <div className={styles.carHistory}>
      <Field label="차량번호" value={carNumber} />
      <Field label="차명" value={carName} />
      <Field
        label="차종"
        value={map(serializeDictionary, ({ value }, key) => (
          <span key={key} className={styles.carMeta}>
            {value}
          </span>
        ))}
      />
      <Field label="연료" value={fuelDisplay} />
      <Field label="연식" value={yearDisplay} />
      <Field label="최초등록일" value={initialRegistrationDateDisplay} />
      <CarAccidents label="내차피해" value={myCarAccidentList} />
      <CarAccidents label="타차가해" value={otherCarAccidentList} />
      <CarTags>
        {map(tags, ({ name, value }, key) => (
          <CarTag key={key} name={name} value={get(data, value)} />
        ))}
      </CarTags>
    </div>
  );
};

export default CarHistory;
