import React, { FC, useCallback, useEffect } from 'react';
import styles from './CommentForm.module.scss';
import { useFormik } from 'formik';
import Ink from 'react-ink';
import { useDispatch } from 'react-redux';
import { CarsActions } from '../../store/cars';

interface Props {
  id: number;
  initialValue: string;
}

interface CommentFormValues {
  comment: string;
}

const CommentForm: FC<Props> = ({ id, initialValue }) => {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values: CommentFormValues) => {
      dispatch(CarsActions.setCarInformationDetail(id, { comment: values.comment }));
    },
    [id, dispatch]
  );

  const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      comment: ''
    },
    onSubmit
  });

  useEffect(() => {
    setFieldValue('comment', initialValue);
  }, [setFieldValue, initialValue]);

  return (
    <form onSubmit={handleSubmit} className={styles.commentForm}>
      <textarea className={styles.textarea} name="comment" value={values.comment} onChange={handleChange} />
      <button className={styles.button} type="submit">
        저장
        <Ink />
      </button>
    </form>
  );
};

export default CommentForm;
