import React from 'react';
import styles from './VehicleRegisterError.module.scss';

interface Props {
  resultMessage: string;
  resultCode?: string;
}
const VehicleRegisterError: React.FC<Props> = ({ resultMessage, resultCode }) => {
  return (
    <div className={styles.vehicleRegisterError}>
      <div className={styles.message}>{resultMessage}</div>
      <div className={styles.codeWrapper}>
        {resultCode && (
          <div>
            에러 코드: <span className={styles.code}>{resultCode}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VehicleRegisterError;
