import { all, call, put, take, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux';
import { LoadingActions } from '../store/loading';

function getPureType(type: string) {
  type = type
    .replace('/INITIAL', '')
    .replace('/SUCCESS', '')
    .replace('/FAILURE', '')
    .replace('/CANCEL', '');
  return type;
}

export default function*() {
  yield all([watchOpenAsyncAction(), watchCloseAsyncAction()]);
}

function* setLoading(type: string) {
  yield put(LoadingActions.setAsync(type, true));
}

function* watchOpenAsyncAction() {
  yield takeEvery('*', function*(action: Action) {
    if (/\/INITIAL$/g.test(action.type)) {
      const type = getPureType(action.type);
      yield call(setLoading, type);
    }
  });
}

function* watchCloseAsyncAction() {
  while (true) {
    const action = yield take((action: Action) => {
      return /\/SUCCESS$/g.test(action.type) || /\/FAILURE$/g.test(action.type) || /\/CANCEL$/g.test(action.type);
    });

    const type = getPureType(action.type);

    yield put(LoadingActions.setAsync(type, false));
  }
}
