import React, { FC, useCallback } from 'react';
import styles from './LoginForm.module.scss';
import { useFormik } from 'formik';
import { useToast } from '../../hooks/useToast';
import { useDispatch } from 'react-redux';
import { UserActions } from '../../store/user';
import Button from '../Button/Button';
import TextInput from '../TextInput/TextInput';
import Typography from '../Typography/Typography';

interface Props {}

interface LoginFormValues {
  username: string;
  password: string;
}

const LoginForm: FC<Props> = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const onSubmit = useCallback(({ username, password }: LoginFormValues) => {
    if (username === '') {
      toast.info('계정을 입력해주세요.');
      return;
    }

    if (password === '') {
      toast.info('비밀번호를 입력해주세요.');
      return;
    }

    dispatch(UserActions.login(username, password));
  }, [toast, dispatch]);

  const { values, handleSubmit, handleChange } = useFormik<LoginFormValues>({
    initialValues: {
      username: '',
      password: ''
    },
    onSubmit
  });

  return (
    <div className={styles.loginForm}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <Typography variant="h1">로그인</Typography>
        <TextInput className={styles.input} placeholder="계정" name="username" value={values.username} onChange={handleChange} />
        <TextInput
          className={styles.input}
          placeholder="비밀번호"
          type="password"
          name="password"
          value={values.password}
          onChange={handleChange}
        />
        <div className={styles.actions}>
          <Button className={styles.loginButton} type="submit">로그인</Button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
