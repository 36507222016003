import React from 'react';
import styles from './RawDataPopUp.module.scss';
import Modal from '../Modal/Modal';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  rawResponseBody?: any;
}
const syntaxHighlight = (json: string) => {
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      var cls = styles.number;
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = styles.key;
        } else {
          cls = styles.string;
        }
      } else if (/true|false/.test(match)) {
        cls = styles.boolean;
      } else if (/null/.test(match)) {
        cls = styles.null;
      }
      return '<span class="' + cls + '">' + match + '</span>';
    }
  );
};
const RawDataPopUp: React.FC<Props> = ({ isVisible, onClose, rawResponseBody }) => {
  return (
    <Modal isVisible={isVisible} onClose={onClose} className={styles.rawDataPopUp}>
      {rawResponseBody && (
        <pre
          dangerouslySetInnerHTML={{
            __html: syntaxHighlight(JSON.stringify(rawResponseBody, null, 2)),
          }}
        />
      )}
    </Modal>
  );
};

export default RawDataPopUp;
