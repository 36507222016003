import React, { FC } from 'react';
import styles from './CarTag.module.scss';

interface Props {
  name: string;
  value: number;
}

const CarTag: FC<Props> = ({ name, value }) => {
  if (value === null || value === undefined || value <= 0) {
    return null;
  }

  return (
    <div className={styles.carTag}>
      <span className={styles.name}>{name}</span>
      <span className={styles.value}>{value}회</span>
    </div>
  );
};

export default CarTag;
