import React, { ButtonHTMLAttributes, FC } from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';
import Ink from 'react-ink';
import Loading from '../Loading/Loading';

interface Props {
  className?: string;
  variant?: 'primary' | 'danger';
  loading?: boolean;
}

const Button: FC<Props & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  variant = 'primary',
  children,
  loading,
  ...otherProps
}) => {
  return (
    <button className={classNames(styles.button, styles[variant], className)} {...otherProps}>
      {children}
      <Ink />
      {loading && (
        <div className={styles.loading}>
          <Loading variant="white" />
        </div>
      )}
    </button>
  );
};

export default Button;
