import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import styles from './Histories.module.scss';
import { useQuery } from '../../hooks/useQuery';
import { useDispatch } from 'react-redux';
import { CarsActions, CarsTypes } from '../../store/cars';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { get, map, flatten } from 'lodash';
import { useHistory } from 'react-router';
import Ink from 'react-ink';
import HistorySearchBar from './HistorySearchBar/HistorySearchBar';

interface Props {}

const Histories: FC<Props> = () => {
  const [key, setKey] = useState(Math.random());
  const [page, setPage] = useState(1);
  const currentQuery = useQuery();
  const search = currentQuery.get('search') || '';
  const history = useHistory();
  const dispatch = useDispatch();
  const scrollElement = useRef<HTMLDivElement | null>(null);
  const isMounted = useRef(false);
  const isSuspend = useRef(false);

  const { isStop, isLoading, carInformationList } = useTypedSelector(
    ({ cars: { isStop, carInformationList }, loading: { asyncMap } }) => {
      return {
        isStop,
        isLoading: get(asyncMap, CarsTypes.GET_CAR_INFORMATION) > 0,
        carInformationList: flatten(carInformationList)
      };
    }
  );

  const query = useMemo(() => {
    const query = new URLSearchParams();
    query.set('page', page.toString());

    if (search) {
      query.set('comment', search);
    }

    query.set('envelope', 'true');

    return query;
  }, [page, search]);

  useEffect(() => {
    if (isMounted.current) {
      isSuspend.current = true;
    }

    dispatch(CarsActions.truncateCarInformation());
  }, [search, dispatch]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isSuspend.current) {
      setKey(Math.random());
      setPage(1);
      isSuspend.current = false;
    } else {
      dispatch(CarsActions.getCarInformation(query));
    }
  }, [key, query, dispatch]);

  useEffect(() => {
    const ref = scrollElement.current;
    const onScroll = () => {
      if (isLoading || isStop || ref === null) {
        return;
      }

      if (ref.scrollTop + ref.offsetHeight >= ref.scrollHeight) {
        setPage(prevPage => prevPage + 1);
      }
    };

    if (ref) {
      ref.addEventListener('scroll', onScroll);
    }

    return () => {
      if (ref) {
        ref.removeEventListener('scroll', onScroll);
      }
    };
  }, [isLoading, isStop]);

  useEffect(() => {
    return () => {
      dispatch(CarsActions.truncateCarInformation());
    };
  }, [dispatch]);

  return (
    <div className={styles.histories}>
      <HistorySearchBar />
      <div className={styles.items} ref={scrollElement}>
        {map(carInformationList, (carInformation, key) => {
          const carNumber = get(carInformation, 'car_number');
          const carHistoryCarName = get(carInformation, ['carhistory', 'car_name']);
          const vehicleIdentificationCarName = get(carInformation, ['vehicle_identification', 'car_name'])

          return (
            <button
              onClick={() => {
                const query = new URLSearchParams();
                query.set('id', carInformation.id.toString());
                history.push(`?${query.toString()}`);
              }}
              key={key}
              className={styles.columns}
            >
              <span className={styles.first}>
                <span className={styles.column}>
                  <span className={styles.label}>차량번호</span>
                  <span className={styles.value}>{carNumber}</span>
                </span>
                <span className={styles.column}>
                  <span className={styles.label}>보험</span>
                  <span className={styles.value}>{carHistoryCarName || '-'}</span>
                </span>
                <span className={styles.column}>
                  <span className={styles.label}>원부</span>
                  <span className={styles.value}>{vehicleIdentificationCarName || '-'}</span>
                </span>
              </span>
              {carInformation.comment && <span className={styles.comment}>{carInformation.comment}</span>}
              <Ink />
            </button>
          );
        })}

        <button
          className={styles.readMore}
          onMouseOver={() => {
            if (!isStop && !isLoading) {
              setPage(prevPage => prevPage + 1);
            }
          }}
        >
          더보기
        </button>
      </div>
    </div>
  );
};

export default Histories;
