import React, { FC, useEffect, useState } from 'react';
import styles from './VehicleRegister.module.scss';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { get } from 'lodash';
import { CarsActions, CarsTypes } from '../../store/cars';
import CopyText from '../CopyText/CopyText';
import Field from '../Field/Field';
import { FiSearch } from 'react-icons/fi';
import { toDateFormat } from '../../cores/toDateFormat';
import { map } from 'lodash';
import Record from '../Record/Record';
import Loading from '../Loading/Loading';
import VehicleRegisterError from '../VehicleRegisterError/VehicleRegisterError';
import RawDataPopUp from '../RawDataPopUp/RawDataPopUp';
import Ink from 'react-ink';
import { VehicleRegisterResult } from '../../declaration/cars';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useIsMutating } from 'react-query';

const successfulRegisterResults: VehicleRegisterResult[] = ['ok', 'erased'];

const VehicleRegister: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isMutating = useIsMutating({
    mutationKey: ['postVehicleRegister'],
  });

  useEffect(() => {
    const id = new URLSearchParams(location.search).get('id');

    if (!id) {
      return;
    }

    dispatch(CarsActions.getVehicleRegister(Number(id)));
  }, [dispatch, location.key, location.search]);

  const [isOpenRawDataPopUp, setOpenRawDataPopUp] = useState(false);
  const { isLoading, vehicleRegister } = useTypedSelector(({ cars: { vehicleRegister }, loading: { asyncMap } }) => {
    return {
      isLoading: get(asyncMap, CarsTypes.GET_VEHICLE_REGISTER_ID) || get(asyncMap, CarsTypes.GET_VEHICLE_REGISTER) > 0,
      vehicleRegister,
    };
  });
  const year = get(vehicleRegister, 'year');
  const carNumber = get(vehicleRegister, 'car_number');
  const carName = get(vehicleRegister, 'car_name');
  const carType = get(vehicleRegister, 'car_type');
  const color = get(vehicleRegister, 'color');
  const vehicleIdentificationNumber = get(vehicleRegister, 'vin');
  const yearDisplay = year ? `${year}년` : null;
  const initialRegistrationDate = get(vehicleRegister, 'initial_registration_date');
  const manufacturedDate = get(vehicleRegister, 'manufactured_date');
  const finalOwnerName = get(vehicleRegister, 'final_owner_name');
  const identityNumber = get(vehicleRegister, 'identity_number');
  const address = get(vehicleRegister, 'address');
  const inspectionValidPeriod = get(vehicleRegister, 'inspection_valid_period');
  const option = get(vehicleRegister, 'option');
  const engineCode = get(vehicleRegister, 'engine_code');
  const use = get(vehicleRegister, 'use');
  const sourceType = get(vehicleRegister, 'source_type');
  const specManagementNumber = get(vehicleRegister, 'spec_management_number');
  const confirmDate = get(vehicleRegister, 'confirm_date');
  const erasureDate = get(vehicleRegister, 'erasure_date');
  const closeDate = get(vehicleRegister, 'close_date');
  const receiptNumber = get(vehicleRegister, 'receipt_number');
  const documentNumber = get(vehicleRegister, 'document_number');
  const number = get(vehicleRegister, 'number');
  const records = get(vehicleRegister, 'records');
  const resultMessage = get(vehicleRegister, 'result_message');
  const resultCode = get(vehicleRegister, 'result_code');
  const solutionDisplay = get(vehicleRegister, 'solution_display');
  const providerDisplay = get(vehicleRegister, 'provider_display');
  const time = get(vehicleRegister, 'time');

  const registrationDetail = get(vehicleRegister, 'registration_detail');
  const licensePlateType = get(vehicleRegister, 'license_plate_type');
  const managementNumber = get(vehicleRegister, 'management_number');
  const acquiredPrice = get(vehicleRegister, 'acquired_price');
  const rawResponseBody = get(vehicleRegister, 'raw_response_body');

  if (isLoading || isMutating) return <Loading />;
  if (!vehicleRegister) {
    return null;
  }

  if (!successfulRegisterResults.includes(vehicleRegister.result)) {
    return resultMessage ? <VehicleRegisterError resultMessage={resultMessage} resultCode={resultCode} /> : null;
  }

  return (
    <div className={styles.vehicleRegister}>
      <div className={styles.carInfo}>
        <div className={styles.column}>
          <div className={styles.section}>
            <Field label="차량번호" value={<CopyText>{carNumber}</CopyText>} />
            <Field label="차명" value={carName} />
            <Field label="차종" value={carType} />
            <Field
              label="차대번호"
              value={
                vehicleIdentificationNumber ? (
                  <div>
                    <CopyText>{vehicleIdentificationNumber}</CopyText>
                    <button
                      className={styles.vin}
                      onClick={() => {
                        if (!vehicleIdentificationNumber) {
                          return;
                        }
                        window.open(
                          `/vin?a=${vehicleIdentificationNumber}&car_number=${carNumber}`,
                          '',
                          'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=100,top=100'
                        );
                      }}
                    >
                      <FiSearch />
                    </button>
                  </div>
                ) : (
                  '-'
                )
              }
            />
            <Field label="모델연도" value={yearDisplay} />
            <Field
              label="최초등록일"
              value={initialRegistrationDate ? toDateFormat(initialRegistrationDate, 'yyyy-MM-dd') : null}
            />
            <Field label="제작연월식" value={manufacturedDate ? toDateFormat(manufacturedDate, 'yyyy-MM-dd') : null} />
            <Field label="색상" value={color} />
          </div>
          <div className={styles.section}>
            <Field label="최종소유" value={finalOwnerName} />
            <Field label="주민(법인)번호" value={identityNumber} /> <Field label="사용본거지" value={address} />
          </div>
          <div className={styles.section}>
            <Field label="등록상세" value={registrationDetail} />
            <Field label="번호판 유형" value={licensePlateType} />
            <Field label="관리 번호" value={managementNumber} />
            <Field label="취득 가격" value={acquiredPrice} />
            <span onClick={() => setOpenRawDataPopUp(true)} className={styles.rawDataButton}>
              미가공 데이터
              <Ink />
            </span>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.section}>
            <Field label="검사 유효기간" value={inspectionValidPeriod} />
            <Field label="세부유형" value={option} />
          </div>
          <div className={styles.section}>
            <Field label="원동기형식" value={engineCode} />
            <Field label="용도" value={use} />
            <Field label="출처구분" value={sourceType} />
          </div>
          <div className={styles.section}>
            <Field label="제원관리번호" value={specManagementNumber} />
            <Field label="등록사항 확인일" value={confirmDate ? toDateFormat(confirmDate, 'yyyy-MM-dd') : null} />
            <Field label="말소 확인일" value={erasureDate ? toDateFormat(erasureDate, 'yyyy-MM-dd') : null} />
            <Field label="폐쇄일" value={closeDate ? toDateFormat(closeDate, 'yyyy-MM-dd') : null} />
          </div>
          <div className={styles.section}>
            <Field label="접수번호" value={receiptNumber} />
            <Field label="문서확인번호" value={documentNumber} />
            <Field label="호수" value={number} />
          </div>
          <div className={styles.section}>
            <Field label="솔루션" value={solutionDisplay} />
            <Field label="공급처" value={providerDisplay} />
            <Field label="시간" value={time} />
          </div>
        </div>
      </div>
      <div className={styles.recordContainer}>
        {map(records, (record) => (
          <Record record={record} />
        ))}
      </div>
      <RawDataPopUp isVisible={isOpenRawDataPopUp} onClose={() => setOpenRawDataPopUp(false)} rawResponseBody={rawResponseBody} />
    </div>
  );
};

export default VehicleRegister;
