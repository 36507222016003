import React, { FC, memo } from 'react';
import LoginForm from '../LoginForm/LoginForm';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Route, Switch } from 'react-router';
import Search from '../../pages/Search/Search';
import VehicleRegisterPage from '../../pages/VehicleRegisterPage';
import CarSpecPage from '../../pages/CarSpecPage';
import MerchandiseCheckPage from '../../pages/MerchandiseCheckPage';
import Layout from '../Layout/Layout';

interface Props {}

const App: FC<Props> = memo(() => {
  const { token } = useTypedSelector(({ user: { token } }) => ({ token }));
  const isAuthenticated = token !== null;

  return (
    <>
      {isAuthenticated ? (
        <Layout>
          <Switch>
            <Route exact path="/" component={Search} />
            <Route exact path="/register" component={VehicleRegisterPage} />
            <Route path="/vin" component={CarSpecPage} />
            <Route path="/merchandise-check" component={MerchandiseCheckPage} />
          </Switch>
        </Layout>
      ) : (
        <LoginForm />
      )}
    </>
  );
});

export default App;
