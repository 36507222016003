import React from 'react';
import styles from './Record.module.scss';
import { Record as IRecord } from '../../declaration/cars';
import { get } from 'lodash';
import classNames from 'classnames';

interface Props {
  record: IRecord;
}
const Record: React.FC<Props> = ({ record }) => {
  const content = get(record, 'content');
  const mainNumber = get(record, 'main_number');
  const subNumber = get(record, 'sub_number');
  const title = get(record, 'title');
  const hasMainNumber = mainNumber !== '';
  const hasSubNumber = subNumber !== '';
  const date = get(record, 'date');
  return (
    <div className={classNames(styles.record, { [styles.hasMainNumber]: hasMainNumber, [styles.hasSubNumber]: hasSubNumber })}>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{title}</span>
        <div className={styles.numberWrapper}>
          <span className={styles.number}>{hasMainNumber ? `주등록 ${mainNumber}` : `부기등록 ${subNumber}`}</span>
          <span className={styles.date}>{date}</span>
        </div>
      </div>
      <div className={styles.content}>{content}</div>
    </div>
  );
};

export default Record;
