import React, { CSSProperties, FC, useCallback, useRef } from 'react';
import styles from './CopyText.module.scss';
import { useToast } from '../../hooks/useToast';

interface Props {
  style?: CSSProperties;
  children?: React.ReactNode;
  content?: string;
}

const CopyText: FC<Props> = ({ style, children, content }) => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const toast = useToast();
  const onClick = useCallback(() => {
    if (!ref.current) {
      return
    }

    const input = document.createElement('input');
    input.style.position = 'absolute';
    input.value = content ? content : ref.current.innerText;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    toast.success('복사되었습니다.');
  }, [toast]);

  return (
    <span style={style} ref={ref} className={styles.copyText} onClick={onClick}>
      {children}
    </span>
  );
};

export default CopyText;
