import axios from 'axios';
import produce from 'immer';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { action, PayloadAction } from 'typesafe-actions';
import { handleActions } from 'redux-actions';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { defaultToken } from '../cores/defaultToken';

export enum UserTypes {
  login = '@user/login',
  logout = '@user/logout'
}

export interface UserState {
  token: string | null;
}

export const UserActions = {
  login: createAsyncSagaAction(UserTypes.login, (username: string, password: string) => {
    return axios.post('/login/', { username, password, device_type: 'pc' });
  }),
  logout: () => action(UserTypes.logout)
};

const initialState: UserState = {
  token: defaultToken
};

export default handleActions<UserState, any>(
  {
    [UserTypes.logout]: state => {
      return produce(state, draft => {
        draft.token = null;
      });
    },
    ...createAsyncSagaReducerMap(UserTypes.login, {
      onSuccess: (state, action: PayloadAction<string, { data: { login: string | null } }>) => {
        const token = action.payload.data.login;

        if (token !== null) {
          return produce(state, draft => {
            draft.token = token;
          });
        }
        return state;
      }
    })
  },
  initialState
);
