import produce from 'immer';
import { handleActions } from 'redux-actions';
import { Toast, ToastStatus, NewToast, UpdateToast } from '../declaration/toast';
import { action, PayloadAction } from 'typesafe-actions';
import { uniqueId, findIndex } from 'lodash';

export enum ToastTypes {
  createToast = '@toast/createToast',
  updateToast = '@toast/updateToast',
  deleteToast = '@toast/deleteToast'
}

export interface ToastState {
  toasts: Array<Toast>;
}

export const ToastActions = {
  createToast: ({ variant, message }: NewToast) => {
    return action(ToastTypes.createToast, {
      id: uniqueId('toast-'),
      status: ToastStatus.Display,
      variant,
      message,
      duration: 2000
    });
  },
  updateToast: (updateToast: UpdateToast) => {
    return action(ToastTypes.updateToast, updateToast);
  },
  deleteToast: (id: string) => {
    return action(ToastTypes.deleteToast, id);
  }
};

const initialState: ToastState = {
  toasts: []
};

export default handleActions<ToastState, any>(
  {
    [ToastTypes.createToast]: (state, action: PayloadAction<string, Toast>) => {
      return produce(state, draft => {
        draft.toasts.push(action.payload);
      });
    },
    [ToastTypes.updateToast]: (state, action: PayloadAction<string, UpdateToast>) => {
      const targetIndex = findIndex(state.toasts, ({ id }) => id === action.payload.id);

      if (targetIndex === -1) {
        return state;
      }

      return produce(state, draft => {
        draft.toasts[targetIndex] = { ...state.toasts[targetIndex], ...action.payload };
      });
    },
    [ToastTypes.deleteToast]: (state, action: PayloadAction<string, string>) => {
      const targetIndex = findIndex(state.toasts, ({ id }) => id === action.payload);

      if (targetIndex === -1) {
        return state;
      }

      return produce(state, draft => {
        draft.toasts.splice(targetIndex, 1);
      });
    }
  },
  initialState
);
