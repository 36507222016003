import React, { FC, useCallback, useEffect } from 'react';
import styles from './HistorySearchBar.module.scss';
import { useFormik } from 'formik';
import { FiSearch } from 'react-icons/fi';
import { useHistory } from 'react-router';
import { useQuery } from '../../../hooks/useQuery';
import Button from '../../Button/Button';
import TextInput from '../../TextInput/TextInput';

interface Props {}

interface HistorySearchBarValues {
  search: string;
}

const HistorySearchBar: FC<Props> = () => {
  const query = useQuery();
  const search = query.get('search');
  const history = useHistory();
  const onSubmit = useCallback(
    (values: HistorySearchBarValues) => {
      if (values.search === '') {
        query.delete('search');
      } else {
        query.set('search', values.search);
      }

      history.push(`?${query.toString()}`);
    },
    [query, history]
  );

  const { values, setFieldValue, handleChange, handleSubmit } = useFormik<HistorySearchBarValues>({
    initialValues: {
      search: search || ''
    },
    onSubmit
  });

  useEffect(() => {
    setFieldValue('search', search || '');
  }, [search, setFieldValue]);

  return (
    <form className={styles.historySearchBar} onSubmit={handleSubmit}>
      <div className={styles.searchBar}>
        <TextInput
          icon={<FiSearch />}
          name="search"
          value={values.search}
          className={styles.input}
          placeholder="코멘트 검색"
          onChange={handleChange}
        />
        <Button className={styles.submit} type="submit">
          검색
        </Button>
      </div>
    </form>
  );
};

export default HistorySearchBar;
