import React, { FC } from 'react';
import styles from './Typography.module.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'p';
}

const Typography: FC<Props> = ({ className, variant = 'p', children }) => {
  return <div className={classNames(styles.typography, styles[variant], className)}>{children}</div>;
};

export default Typography;
