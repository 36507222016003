import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import styles from './CarSpec.module.scss';
import { chain, map, get, forEach } from 'lodash';
import { CarsActions } from '../../store/cars';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import classNames from 'classnames';
import { useQuery } from '../../hooks/useQuery';
import Loading from '../Loading/Loading';
import { CarSpec as ICarSpec, CarSpecDetail as ICarSpecDetail } from '../../declaration/cars';
import { useHistory } from 'react-router';
import { MdClose, MdAdd, MdSearch, MdFilter1, MdFilter2 } from 'react-icons/md';
import Ink from 'react-ink';
import CarSpecDetail from './CarSpecDetail/CarSpecDetail';

interface Props {}

const keys = ['category', 'group_code', 'group_name', 'code', 'name'];

interface Detail {
  category: string | null;
  group_code: string | null;
  group_name: string | null;
  code: string | null;
  name: string | null;
}

const CarSpecBasicInformation: FC<{
  variant: 'a' | 'b';
  vin: string | null;
  carNumber?: string | null;
  basicInformation: ICarSpec['basic_information'];
}> = memo(({ variant, vin, carNumber, basicInformation }) => {
  const query = useQuery();
  const history = useHistory();

  return (
    <div className={styles.carSpecBasicInformation}>
      {variant === 'b' && (
        <button
          onClick={() => {
            query.delete('b');
            history.push(`?${query.toString()}`);
          }}
          className={styles.closeButton}
        >
          <MdClose />
        </button>
      )}
      <div className={styles.field}>
        <span className={styles.label}>VIN</span>
        <span className={styles.value}>{vin}</span>
      </div>
      <div className={styles.field}>
        <span className={styles.label}>{carNumber ? '차량번호' : ''} </span>
        <span className={styles.value}>{carNumber}</span>
      </div>
      {map(basicInformation, (field, key) => {
        const label = get(field, 'label');
        const value = get(field, 'value');

        return (
          <div key={key} className={styles.field}>
            <span className={styles.label}>{label}</span>
            <span className={styles.value}>{value || '-'}</span>
          </div>
        );
      })}
    </div>
  );
});

function diffDetails(a: Array<ICarSpecDetail>, b: Array<ICarSpecDetail>, isComparison: boolean) {
  const rows: { [key: string]: any } = {};

  for (let i = 0, length = a.length; i < length; i++) {
    const { name: aName, code: aCode, category: aCategory } = a[i];

    let isFind = false;
    let isChanged = false;
    for (let j = 0, length2 = b.length; j < length2; j++) {
      const { name: bName, code: bCode, category: bCategory } = b[j];

      if (aCode === bCode) {
        isFind = true;
        isChanged = aCategory !== bCategory;

        rows[aCode] = {
          comparison: isChanged ? 'change' : 'unchange',
          detail: a[i],
        };

        break;
      }
    }

    if (!isFind) {
      rows[aCode] = {
        comparison: isComparison ? 'insert' : 'unchange',
        detail: a[i],
      };
    }
  }

  return rows;
}

function filterDetails(filter: string, details: Array<ICarSpecDetail>): Array<ICarSpecDetail> {
  if (!filter) {
    return details;
  }

  const upperCaseFilter = filter.toUpperCase();

  const filteredDetails: Array<ICarSpecDetail> = [];

  for (let i = 0, length = details.length; i < length; i++) {
    const { name, code, category } = details[i];

    if (
      (name && name.toUpperCase().indexOf(upperCaseFilter) !== -1) ||
      (code && code.toUpperCase().indexOf(upperCaseFilter) !== -1) ||
      (category && category.toUpperCase().indexOf(upperCaseFilter) !== -1)
    ) {
      filteredDetails.push(details[i]);
    }
  }

  return filteredDetails;
}

const CarSpec: FC<Props> = memo(({}) => {
  const query = useQuery();
  const a = query.get('a');
  const b = query.get('b');
  const history = useHistory();
  const carNumber = query.get('car_number');
  const bVinInputRef = useRef<HTMLInputElement | null>(null);
  const [filter, setFilter] = useState('');
  const [isVisibleInput, setVisibleInput] = useState(false);
  const [isChangedOnly, setChangedOnly] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!a) {
      return;
    }

    dispatch(CarsActions.getCarSpec(a));
  }, [a]);

  useEffect(() => {
    if (!b) {
      return;
    }

    dispatch(CarsActions.getCarSpec(b));
  }, [b]);

  const { aVin, bVin } = useTypedSelector(({ cars: { carSpecMap } }) => ({
    aVin: a ? get(carSpecMap, a) || null : null,
    bVin: b ? get(carSpecMap, b) || null : null,
  }));

  const isLoading = useMemo(() => {
    return (a ? aVin === null : false) || (b ? bVin === null : false);
  }, [a, b, aVin, bVin]);

  const isComparison = a !== null && b !== null;

  const comparisonVin = useMemo(() => {
    if (!aVin) {
      return null;
    }

    const aDetails = get(aVin, 'details') || [];
    const bDetails = get(bVin, 'details') || [];

    const aFilteredDetails = filterDetails(filter, aDetails);
    const bFilteredDetails = filterDetails(filter, bDetails);

    const a = diffDetails(aFilteredDetails, bFilteredDetails, isComparison);
    const b = diffDetails(bFilteredDetails, aFilteredDetails, isComparison);
    const uniqKeys = chain([...Object.keys(a), ...Object.keys(b)])
      .uniq()
      .sort()
      .value();
    const rows = [];

    for (let i = 0; i < uniqKeys.length; i++) {
      const key = uniqKeys[i];

      rows.push({
        a: get(a, key),
        b: get(b, key),
      });
    }

    if (isChangedOnly) {
      return chain(rows)
        .filter((i) => {
          if (get(i.a, 'comparison') === 'unchange' || get(i.b, 'comparison') === 'unchange') {
            return false;
          }

          return true;
        })
        .value();
    }

    return rows;
  }, [aVin, bVin, filter, isComparison, isChangedOnly]);

  useEffect(() => {
    window.resizeTo(b ? 1200 : 600, 800);
  }, [b]);

  const getEmptyColumns = (details: Detail[]) => {
    return keys.map((key) => {
      const values = details.map((detail) => detail && detail[key as keyof Detail]);
      if (values.every((value: string | null) => value === null || value === undefined)) {
        return key;
      }
    });
  };

  const renderedTables = useMemo(() => {
    if (!comparisonVin) {
      return null;
    }

    const renderedA = [];
    const renderedB = [];

    const ADetails = comparisonVin.map((comparison) => comparison.a?.detail);
    const AEmptyColumns = getEmptyColumns(ADetails);

    const BDetails = comparisonVin.map((comparison) => comparison.b?.detail);
    const BEmptyColumns = getEmptyColumns(BDetails);

    for (let i = 0; i < comparisonVin.length; i++) {
      const { a, b } = comparisonVin[i];
      const no = i + 1;

      renderedA.push(<CarSpecDetail key={no} comparison={get(a, 'comparison')} no={no} data={a} emptyColumns={AEmptyColumns} />);

      if (isComparison) {
        renderedB.push(
          <CarSpecDetail key={no} comparison={get(b, 'comparison')} no={no} data={b} emptyColumns={BEmptyColumns} />
        );
      }
    }

    const thead = (emptyColumns: (string | undefined)[]) => {
      return (
        <thead className={styles.thead}>
          <tr>
            <th className={styles.no}>번호</th>
            {!emptyColumns.includes('category') && <th className={styles.category}>카테고리</th>}
            {!emptyColumns.includes('group_code') && <th className={styles.groupCode}>그룹 코드</th>}
            {!emptyColumns.includes('group_name') && <th className={styles.groupName}>그룹 이름</th>}
            {!emptyColumns.includes('code') && <th className={styles.code}>코드</th>}
            {!emptyColumns.includes('name') && <th className={styles.name}>이름</th>}
          </tr>
        </thead>
      );
    };

    return (
      <React.Fragment>
        <table>
          {thead(AEmptyColumns)}
          <tbody>{renderedA}</tbody>
        </table>
        {isComparison && (
          <table>
            {thead(BEmptyColumns)}
            <tbody>{renderedB}</tbody>
          </table>
        )}
      </React.Fragment>
    );
  }, [comparisonVin]);

  if (isLoading) {
    return <Loading />;
  }

  const aBasicInformation = get(aVin, 'basic_information');
  const bBasicInformation = get(bVin, 'basic_information');

  return (
    <div className={styles.carSpec}>
      <div className={classNames(styles.header, isComparison && styles.isComparison)}>
        <div className={styles.informations}>
          {aBasicInformation && (
            <CarSpecBasicInformation variant="a" vin={a} carNumber={carNumber} basicInformation={aBasicInformation} />
          )}
          {bBasicInformation && <CarSpecBasicInformation variant="b" vin={b} basicInformation={bBasicInformation} />}
        </div>
        <div className={styles.actions}>
          <div style={{ display: 'flex' }}>
            <div className={styles.filterInput}>
              <MdSearch />
              <input
                type="text"
                value={filter}
                onChange={(e) => {
                  setFilter(e.currentTarget.value);
                }}
              />
            </div>
            <button
              className={styles.changedOnlyButton}
              onClick={() => {
                setChangedOnly((prevState) => !prevState);
              }}
            >
              {isChangedOnly ? <MdFilter1 /> : <MdFilter2 />}
            </button>
          </div>
          <div className={classNames(styles.plus, isVisibleInput && styles.expand)}>
            <button
              onClick={() => {
                setVisibleInput((prevState) => !prevState);
                if (!isVisibleInput) {
                  setTimeout(() => {
                    if (bVinInputRef.current) {
                      bVinInputRef.current.focus();
                    }
                  });
                }
              }}
            >
              <MdAdd />
              <Ink />
            </button>
            <input
              ref={bVinInputRef}
              placeholder="VIN"
              type="text"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  query.set('b', e.currentTarget.value);
                  history.push(`?${query.toString()}`);
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className={classNames(styles.carSpecDetails, isComparison && styles.isComparison)}>{renderedTables}</div>
    </div>
  );
});

export default CarSpec;
