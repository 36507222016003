import React from 'react';
import styles from './Modal.module.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { animated, config, useTransition } from 'react-spring';

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const Modal: React.FC<ModalProps> = ({ isVisible, children, onClose, disabled = false, className }) => {
  const transitions = useTransition(isVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
    unique: true,
  });

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div className={styles.dimmer} key={key} style={props}>
              <OutsideClickHandler onOutsideClick={onClose} disabled={disabled}>
                <div className={classNames(styles.modal, className)}>{children}</div>
              </OutsideClickHandler>
            </animated.div>
          )
      )}
    </>
  );
};

export default Modal;
