import React, { FC, memo } from 'react';
import { chain, get } from 'lodash';
import classNames from 'classnames';
import CopyText from '../../CopyText/CopyText';
import styles from './CarSpecDetail.module.scss';

interface Props {
  comparison: string;
  no: number;
  data: any;
  emptyColumns: (string | undefined)[];
}
const CarSpecDetail: FC<Props> = memo(({ comparison, no, data, emptyColumns }) => {
  const detail = get(data, 'detail');
  const code = get(detail, 'code');
  const groupName = get(detail, 'group_name');
  const groupCode = get(detail, 'group_code');
  const nameChangedList = get(data, 'nameChangedList');
  return (
    <tr
      className={classNames(
        styles.carSpecDetail,
        !data && styles.isEmpty,
        comparison ? get(styles, comparison) : null,
        nameChangedList && styles.insert
      )}
    >
      {detail ? (
        <React.Fragment>
          <td className={styles.no}>
            <div className={styles.symbol}>{comparison === 'insert' && '+'}</div>
            {no}
          </td>
          {!emptyColumns.includes('category') && (
            <td className={styles.category}>{get(detail, 'category') || <span className={styles.hidden}>-</span>}</td>
          )}
          {!emptyColumns.includes('group_code') && (
            <td className={styles.groupCode}>
              {groupCode ? <CopyText>{groupCode}</CopyText> : <span className={styles.hidden}>-</span>}
            </td>
          )}
          {!emptyColumns.includes('group_name') && (
            <td className={styles.groupName}>
              {groupName ? <CopyText>{groupName}</CopyText> : <span className={styles.hidden}>-</span>}
            </td>
          )}
          {!emptyColumns.includes('code') && (
            <td className={styles.code}>{code ? <CopyText>{code}</CopyText> : <span className={styles.hidden}>-</span>}</td>
          )}
          {!emptyColumns.includes('name') && (
            <td className={styles.name}>
              {nameChangedList
                ? chain(nameChangedList)
                    .map(({ type, a }, key) => {
                      return (
                        <span key={key} className={classNames(styles.word, get(styles, type))}>
                          {a}
                        </span>
                      );
                    })
                    .value()
                : get(detail, 'name') || <span className={styles.hidden}>-</span>}
            </td>
          )}
        </React.Fragment>
      ) : (
        <td colSpan={4} className={styles.empty} />
      )}
    </tr>
  );
});

export default CarSpecDetail;
