import React, { FC } from 'react';
import styles from './CarRecord.module.scss';

interface Props {
  label: string;
  count: number;
}

const CarRecord: FC<Props> = ({ label, count, children }) => {
  return (
    <div className={styles.carRecord}>
      <div className={styles.header}>
        <span className={styles.label}>{label}</span>
        {count > 0 && <span>{count}회</span>}
      </div>
      <div className={styles.children}>
        {children}
      </div>
    </div>
  );
};

export default CarRecord;
