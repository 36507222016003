import React, { FC } from 'react';
import styles from './Layout.module.scss';
import Header from '../Header/Header';
import { useLocation } from 'react-router';
import classNames from 'classnames';

interface Props {}

const Layout: FC<Props> = ({ children }) => {
  const location = useLocation();

  return (
    <div className={styles.layout}>
      <Header />
      <div className={classNames(styles.contentWrapper, location.pathname.indexOf('/vin') !== -1 && styles.isEmpty)}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
