import React from 'react';
import styles from './RadioGroups.module.scss';
import { chain } from 'lodash';
import classNames from 'classnames';

interface Radio {
  name: string;
  value: string[] | string;
}
interface Props {
  radios: Radio[];
  selectedIndex: number;
  onClick: (selectedIndex: number) => void;
}

const RadioGroups: React.FC<Props> = ({ radios, selectedIndex, onClick }) => {
  return (
    <div className={styles.radioGroups}>
      {chain(radios)
        .map(({ name, value }, key) => {
          const isSelected = key === selectedIndex;
          return (
            <div key={name} className={classNames(styles.radio, isSelected && styles.isSelected)} onClick={() => onClick(key)}>
              <div className={styles.shape} />
              <div>{name}</div>
            </div>
          );
        })
        .value()}
    </div>
  );
};

export default RadioGroups;
