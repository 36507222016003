import './styles/index.module.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import App from './components/App/App';
import axios from 'axios';
import Toasts from './components/Toasts/Toasts';
import createStore from './cores/createStore';
import { defaultToken } from './cores/defaultToken';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store';
import { QueryClient, QueryClientProvider } from 'react-query';

const store = createStore();

const queryClient = new QueryClient();

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

if (defaultToken) {
  axios.defaults.headers = {
    authorization: `JWT ${defaultToken}`,
  };
}

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConnectedRouter history={history}>
        <App />
        <Toasts />
      </ConnectedRouter>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
