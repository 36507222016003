import React, { FC } from 'react';
import styles from './Toasts.module.scss';
import { Portal } from 'react-portal';
import ToastItem from '../ToastItem/ToastItem';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { map } from 'lodash';
import { ToastStatus } from '../../declaration/toast';

interface Props {}

const Toasts: FC<Props> = () => {
  let index = -1;
  const toasts = useTypedSelector(({ toast: { toasts } }) => toasts);

  return (
    <Portal>
      <div className={styles.toasts}>
        {map(toasts, toast => {
          if (toast.status === ToastStatus.Display) {
            index++;
          }

          return <ToastItem key={toast.id} y={index} {...toast} />;
        })}
      </div>
    </Portal>
  );
};

export default Toasts;
