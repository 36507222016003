import React, { FC, memo, useEffect, useMemo } from 'react';
import styles from './Search.module.scss';
import Histories from '../../components/Histories/Histories';
import SearchForm from '../../components/SearchForm/SearchForm';
import CarDetail from '../../components/CarDetail/CarDetail';
import CommentForm from '../../components/CommentForm/CommentForm';
import { useQuery } from '../../hooks/useQuery';
import { useQueryField } from '../../hooks/useQueryField';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { get } from 'lodash';
import { CarsActions, CarsTypes } from '../../store/cars';
import Loading from '../../components/Loading/Loading';

interface Props {}

const Search: FC<Props> = memo(() => {
  const query = useQuery();
  const id = useQueryField(query, 'id');

  const dispatch = useDispatch();
  const { isLoading, carInformationDetailMap } = useTypedSelector(
    ({ cars: { carSpecMap, carInformationDetailMap }, loading: { asyncMap } }) => {
      return {
        isLoading: get(asyncMap, CarsTypes.FIND_CAR_INFORMATION) > 0 || get(asyncMap, CarsTypes.GET_CAR_INFORMATION_DETAIL),
        carInformationDetailMap: carInformationDetailMap,
      };
    }
  );

  const data = useMemo(() => {
    if (typeof id === 'number') {
      return get(carInformationDetailMap, id) || null;
    }

    return null;
  }, [carInformationDetailMap, id]);

  useEffect(() => {
    if (typeof id !== 'number') {
      return;
    }

    dispatch(CarsActions.getCarInformationDetail(id));
  }, [id, dispatch]);

  const comment = get(data, 'comment');

  return (
    <div className={styles.search}>
      <div className={styles.content}>
        <SearchForm />
        {data && (
          <React.Fragment>
            <CarDetail data={data} />
            {typeof id === 'number' && <CommentForm id={id} initialValue={comment || ''} />}
          </React.Fragment>
        )}
      </div>
      <div className={styles.side}>
        <Histories />
      </div>
      {isLoading ? <Loading /> : null}
    </div>
  );
});

export default Search;
